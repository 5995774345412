import { HTTP, authHeader } from './http-service';

class BusinessService {
  search(payload) {
    return HTTP.post(`/businessSearch`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  advancedSearch(payload) {
    return HTTP.post(`/businessSearch/advance`, payload, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  searchApplication(payload) {
    return HTTP.post(`/businessSearch/application?isArchived=${payload.isArchived}`, payload, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  advancedApplicationSearch(payload) {
    return HTTP.post(`/businessSearch/application/advance`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  archiveApplications() {
    return HTTP.put(`/business/application/archive`, {}, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  export(payload) {
    return HTTP.post(`/businessSearch/export`, payload, { headers: authHeader() }).then(
      (response) => {
        return response.data;
      }
    );
  }

  get(payload) {
    return HTTP.get(`/business/${payload.schemeId}/${payload.businessId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getByTMLN(tmln) {
    return HTTP.get(`/business/tmln/${tmln}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getBusinessNameByTMLN(tmln) {
    return HTTP.get(`/business/tmln/businessname/${tmln}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  getTMLNByMembershipReference(membershipReference) {
    return HTTP.get(`/business/tmln/membershipreference/${membershipReference}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  register(payload) {
    return HTTP.post(`/business`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/business`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  delete(payload) {
    return HTTP.delete(`/business/${payload.schemeId}/${payload.businessId}`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  archive(payload) {
    return HTTP.put(`/business/${payload.schemeId}/${payload.businessId}/archive`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  schemeInProgressUpdate(payload) {
    return HTTP.put(`/business/${payload.schemeId}/${payload.businessId}/inprogress`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  changeDeregisteredReason(payload) {
    return HTTP.put(`/business/${payload.schemeId}/${payload.businessId}/deregisteredreason`, payload, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }

  exportBusinessesWithTradesCsv() {
    return HTTP.post(
      '/export/businesseswithtrades',
      {},
      {
        headers: authHeader(),
        responseType: 'blob',
      }
    )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: 'text/csv',
        });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'BusinessDataDownload.csv';
        link.click();
        URL.revokeObjectURL(link.href);
        Promise.resolve(response);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }

  getFunnelData() {
    return HTTP.get(`/business/funnel`, {
      headers: authHeader(),
    }).then((response) => {
      return response.data;
    });
  }
}

export default new BusinessService();
