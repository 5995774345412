import BusinessService from '../services/business-service';

export const business = {
  namespaced: true,
  state: {
    searchResult: null,
    pageNumber: 0,
    business: null,
    lastSearchConfig: null,
    applicationSearchResult: null,
    applicationPageNumber: 0,
    lastApplicationSearchConfig: null,
    funnelData: null,
  },
  getters: {
    businessHasCurrentRegistration: (state) => {
      if (!state.business) {
        return false;
      }
      const now = Date(Date.now());
      return (
        state.business.currentRegistrationFromDate <= now &&
        (state.business.currentRegistrationToDate === null ||
          state.business.currentRegistrationToDate >= now)
      );
    },
  },
  actions: {
    registerBusiness({ rootState, commit, dispatch }, data) {
      data.schemeId = rootState.auth.account.scheme.schemeId;
      commit('setIsBusy', true, { root: true });
      return BusinessService.register(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          setTimeout(() => {
            dispatch('executeLastSearch');
          }, 6000);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    executeLastSearch({ dispatch, state }) {
      if (!state.lastSearchConfig) {
        return;
      }
      dispatch('searchBusiness', state.lastSearchConfig).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    searchBusiness({ commit }, data) {
      data.type = 'standard';
      commit('setLastSearchConfig', data);
      return BusinessService.search(data).then(
        (result) => {
          commit('setBusinessSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    searchBusinessAdvanced({ commit }, data) {
      data.type = 'advanced';
      commit('setLastSearchConfig', data);
      return BusinessService.advancedSearch(data).then(
        (result) => {
          commit('setBusinessSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    searchBusinessApplication({ commit }, data) {
      data.type = 'standard';
      commit('setLastApplicationSearchConfig', data);
      return BusinessService.searchApplication(data).then(
        (result) => {
          commit('setBusinessApplicationSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    searchBusinessApplicationAdvanced({ commit }, data) {
      data.type = 'advanced';
      commit('setLastApplicationSearchConfig', data);
      return BusinessService.advancedApplicationSearch(data).then(
        (result) => {
          commit('setBusinessApplicationSearchResult', result);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    archiveApplications() {
      return BusinessService.archiveApplications().then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    export({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return BusinessService.export(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    getBusinessDetails({ rootState, commit }, businessId) {
      commit('setIsBusy', true, { root: true });
      commit('setBusiness', null);
      commit('businessContact/setContacts', [], { root: true });
      commit('businessContact/setContact', null, { root: true });
      commit('businessTradeRegistration/setTradeRegistrations', [], { root: true });
      commit('businessTradeRegistration/setTrade', null, { root: true });
      const payload = {
        businessId,
        schemeId: rootState.auth.account.scheme.schemeId,
      };
      return BusinessService.get(payload).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setBusiness', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    refreshBusinessDetails({ rootState, commit }, businessId) {
      const payload = {
        businessId,
        schemeId: rootState.auth.account.scheme.schemeId,
      };
      return BusinessService.get(payload).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setBusiness', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    updateBusiness({ commit, dispatch }, data) {
      commit('setIsBusy', true, { root: true });
      return BusinessService.update(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          if (result.isSuccess) {
            commit('setBusiness', result.model);
          }
          const { message } = result;
          setTimeout(() => {
            dispatch('executeLastSearch');
          }, 6000);
          return Promise.resolve(message);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    setCurrentRegistration({ commit }, newCurentRegistration) {
      commit('setCurrentRegistration', newCurentRegistration);
    },
    addBusinessRegistrationPeriod({ commit }, newRegistrationPeriod) {
      commit('addBusinessRegistrationPeriod', newRegistrationPeriod);
    },
    setBusinessStatus({ commit }, newStatus) {
      commit('setBusinessStatus', newStatus);
    },
    submitBusiness({ commit, dispatch, state }) {
      commit('setBusinessStatus', 3);
      dispatch('updateBusiness', state.business).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    archiveBusiness({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return BusinessService.archive(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          if (result.isSuccess) {
            commit('setBusiness', result.model);
          }
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    schemeInProgressUpdate({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return BusinessService.schemeInProgressUpdate(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          if (result.isSuccess) {
            commit('setBusiness', result.model);
          }
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    changeDeregisteredReason({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return BusinessService.changeDeregisteredReason(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          if (result.isSuccess) {
            commit('setBusiness', result.model);
          }
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    deleteBusiness({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return BusinessService.delete(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('removeBusinessFromSearchResult', data.businessId);
          const { message } = result;
          return Promise.resolve(message);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    exportBusinessesWithTradesCsv({ commit }) {
      commit('setIsBusy', true, { root: true });
      return BusinessService.exportBusinessesWithTradesCsv().then(
        () => {
          commit('setIsBusy', false, { root: true });
          const { message } = 'Export download complete';
          return Promise.resolve(message);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    getFunnelData({ commit }) {
      return BusinessService.getFunnelData().then(
        (result) => {
          commit('setFunnelData', result.model);
          return Promise.resolve(result.model);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getBusinessNameByTMLN({ commit }, tmln) {
      return BusinessService.getBusinessNameByTMLN(tmln).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getTMLNByMembershipReference({ commit }, membershipReference) {
      return BusinessService.getTMLNByMembershipReference(membershipReference).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    removeBusinessFromSearchResult(state, businessId) {
      if (state.searchResult) {
        const removed = state.searchResult.results.find((x) => x.businessId === businessId);
        if (removed) {
          const newSearchResult = state.searchResult.results.filter(
            (x) => x.schemeBusinessId !== removed.schemeBusinessId
          );
          state.searchResult = newSearchResult;
        }
      }
    },
    setBusinessSearchResult(state, searchResult) {
      state.searchResult = searchResult;
    },
    setBusinessApplicationSearchResult(state, searchResult) {
      state.applicationSearchResult = searchResult;
    },
    setBusiness(state, newBusiness) {
      state.business = newBusiness;
      if (state.business?.applicationSchemeSubmittedDate) {
        state.business.applicationSchemeSubmittedDate = new Date(
          state.business.applicationSchemeSubmittedDate
        );
      }
    },
    setCurrentRegistration(state, newCurentRegistration) {
      state.business.currentRegistrationFromDate = newCurentRegistration.fromDate;
      state.business.currentRegistrationToDate = newCurentRegistration.toDate;
      state.business.isDeregistered = newCurentRegistration.isDeregistered;
      state.business.deregisteredReason = newCurentRegistration.deregisteredReason;
    },
    addBusinessRegistrationPeriod(state, newRegistrationPeriod) {
      if (!state.business.registrationPeriods) {
        state.business.registrationPeriods = [];
      }
      state.business.registrationPeriods.push(newRegistrationPeriod);
    },
    setBusinessStatus(state, newStatus) {
      console.log(`setBusinessStatus: ${newStatus}`);
      state.business.status = newStatus.status;
      state.business.statusString = newStatus.statusString;
    },
    setLastSearchConfig(state, searchconfig) {
      state.lastSearchConfig = searchconfig;
    },
    setLastApplicationSearchConfig(state, searchconfig) {
      state.lastApplicationSearchConfig = searchconfig;
    },
    setSearchPageNumber(state, newPageNumber) {
      state.pageNumber = newPageNumber;
    },
    setApplicationSearchPageNumber(state, newPageNumber) {
      state.applicationPageNumber = newPageNumber;
    },
    setFunnelData(state, funnelData) {
      state.funnelData = funnelData;
    },
  },
};
